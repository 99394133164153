@tailwind base;
@tailwind components;
@tailwind utilities;

/* Inter and Lora fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lora:ital,wght@0,400..700;1,400..700&display=swap");

img {
  display: block;
  max-width: 100%;
}

h1 {
  font-size: 4rem;
  line-height: 1.5;
}

h2 {
  font-size: 3rem;
  line-height: 1.25;
}

h3 {
  font-size: 2.25rem;
  line-height: 1.25;
}

h1,
h2,
h3 {
  font-family: "Lora", serif;
  font-weight: 600;
}

.p1-r,
.p1-b {
  font-size: 1.75rem;
}

.p2-r,
.p2-b {
  font-size: 1.25rem;
}

.p3-r,
.p3-b {
  font-size: 1rem;
}

.p4-r {
  font-size: 0.875rem;
}

.p1-b,
.p2-b,
.p3-b {
  font-weight: 500;
}

.p1-r,
.p1-b,
.p2-r,
.p2-b,
.p3-r,
.p3-b,
.p4-r {
  font-family: "Inter", sans-serif;
  line-height: 1.5;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;
}

/* Define the animation */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.marquee {
  animation: marquee 40s linear infinite;
}

.no-mobile {
  display: none;
}

@media (max-width: 1199px) {
  .no-mobile {
    display: block;
    text-align: center;
    margin-top: 20%;
    font-size: 18px;
    color: #333;
  }

  #root {
    display: none;
  }
}
